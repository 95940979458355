<template>
  <div style="width:100%;padding:30px;">
    <DocumentAJoindre
      :context="{
        ID_Structure: currentStructureId,
        ID_Saison: selectedSeason.id,
        hasCodeFederal,
      }"
      :isAffiliation="true"
    />
    <v-row>
      <v-col cols="10">
        <v-alert border="left" color="grey lighten-2">
          <p class="mb-0">Information de saisie</p>
          <p class="mb-0 font-weight-bold">A chaque téléversement de document, l’étape se met à jour.</p>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import documentAffiliation from "@/components/Affiliations/Affiliation_StructureDocUpload.vue";
import DocumentAJoindre from "@/components/GestionDesDocuments/GestionDesDocuments.vue";

export default {
  components: { DocumentAJoindre },

  data: () => ({
    mounted: false,
    selectedSeason: null,
  }),

  async created() {
    this.selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),
    ...mapGetters("saisons", ["saisons"]),
  },

  methods: {},
};
</script>
